import Container from "react-bootstrap/Container";
import {
    Form,
    json, Link,
    redirect,
    useFetcher,
    useLoaderData,
    useOutletContext,
} from "react-router-dom";
import {Col, FormControl, FormGroup, FormLabel, Row} from "react-bootstrap";
import {useState} from "react";
import Button from "react-bootstrap/Button";
import {ReactComponent as NewTab} from "../../assets/images/icons/top-right.svg"
import {addMinutes, format, lightFormat} from "date-fns";


export const appointmentAdminLoader = (getAuth) => async ({params, request}) => {
    console.log("appointment admin loader")
    let url = new URL(request.url);
    let bdt = url.searchParams.has("bdt")
        ? url.searchParams.get("bdt")
        : new Date().toISOString().split('T')[0];
    let edt = url.searchParams.has("bdt")
        ? url.searchParams.get("edt")
        : new Date().toISOString().split('T')[0];
    try {
        let auth = await getAuth();
        let artist = auth.username === "jockerse" ? "erika" : auth.username;
        let response = await fetch(`https://botanical.ink/api/admin/${artist}/appointments?bdt=${bdt}&edt=${edt}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.idToken.getJwtToken()}`,
            },
        });
        if (response.ok) {
            const data = await response.json();
            return json({
                data: data,
                bdt: bdt,
                edt: edt,
            })

        } else {
            return json({
                error: response.status,
                bdt: bdt,
                edt: edt,
            })
        }
    } catch (error) {
        console.log(error)
        if (error === "NOT VALID") {
            return redirect("/login")
        } else {
            throw error
        }
    }
}

export const appointmentAdminAction = (getAuth) => async ({params, request}) => {
    console.log("appointment admin action")
    let auth = await getAuth();
    let artist = auth.username;

    if (auth.username === "jockerse") {
        artist = "erika";
    } else if (auth.username === "aidan") {
        artist = "calista";
    }

    let formData = await request.formData();
    let aid = formData.get("aid")

    let intent = formData.get("intent");
    console.log("intent: " + intent)
    if (intent === "schedule") {
        let data = {
            newStart: formData.get("newStart"),
            tattooDurationMinutes: formData.get("tattooDurationMinutes"),
            bufferMinutes: formData.get("buffer"),
        }
        try {
            let response = await fetch(`https://botanical.ink/api/admin/${artist}/appointments/${aid}`, {
                method: "post",
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.idToken.getJwtToken()}`,
                }
            });
            if (response.ok) {
                console.log("reschedule ok")
                return {};
            } else {
                console.log("reschedule not ok")
                return response;
            }
        } catch (error) {
            console.log(error)
            if (error === "NOT VALID") {
                return redirect("/login")
            } else {
                throw error
            }
        }
    }

    if (intent === "resReset") {
        try {
            let response = await fetch(`https://botanical.ink/api/admin/${artist}/appointments/${aid}/reschedules/reset`, {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.idToken.getJwtToken()}`,
                }
            });
            if (response.ok) {
                console.log("reset reschedule ok")
                return {};
            } else {
                console.log("reset reschedule not ok")
                return response;
            }
        } catch (error) {
            console.log(error)
            if (error === "NOT VALID") {
                return redirect("/login")
            } else {
                throw error
            }
        }
    }

    throw json(
        {message: "Invalid intent: " + intent},
        {status: 400}
    );
}


export const AppointmentAdmin = () => {
    const outletContext = useOutletContext();
    const loaderData = useLoaderData();

    return (
        <Container className={`${outletContext.darkMode ? "text-light" : "text-black"}`}>
            <h3 className={"text-center"}>Appointments</h3>
            <Form className={`d-grid rounded column-gap-3 row-gap-3 p-3 mb-3 
                ${outletContext.darkMode ? "text-light border border-light " : "text-black bg-success-subtle border border-success"}`}>
                <Row>
                    <FormGroup as={Col} controlId={"bdt"}>
                        <FormLabel>Begin Date</FormLabel>
                        <FormControl name={'bdt'} as={"input"} type={"date"} defaultValue={loaderData.bdt} required/>
                    </FormGroup>
                    <FormGroup as={Col} controlId={"edt"}>
                        <FormLabel>End Date</FormLabel>
                        <FormControl name={"edt"} as={"input"} type={"date"} defaultValue={loaderData.edt} required/>
                    </FormGroup>
                </Row>
                <Row>
                    <Col className={"d-flex justify-content-center"}>
                        <Button className={"w-25"} type={"submit"}>Update</Button>
                    </Col>
                </Row>
            </Form>
            <Container className={`d-grid rounded column-gap-3 row-gap-3 p-3 mb-3 
                ${outletContext.darkMode ? "text-light border border-light " : "text-black bg-success-subtle border border-success"}`}>
                {loaderData.data && Object.entries(loaderData.data)
                    .map(([dateStr, arr]) => [new Date(dateStr + "T00:00:00"), arr])
                    .sort((a, b) => a[0] - b[0])
                    .map(([date, apptArr]) => (
                        <div key={date.getTime()}>
                            <h4 className={" p-1"}>{format(date, "EEEE MMM d, yyyy")}</h4>
                            {apptArr.map((appt) => (
                                <EditAppointment
                                    key={appt.aid}
                                    appointment={appt}
                                    outletContext={outletContext}
                                />
                            ))}
                        </div>

                    ))
                }
            </Container>
        </Container>
    );
}

export const EditAppointment = ({appointment, outletContext}) => {
    const fetcher = useFetcher();

    const [editMode, setEditMode] = useState(false)

    const [start, setStart] = useState(appointment.start)
    const [duration, setDuration] = useState(parseInt(appointment.tattooDurationMinutes))
    const [buffer, setBuffer] = useState(parseInt(appointment.bufferMinutes))
    const [endTime, setEndTime] = useState(appointment.end.split('T')[1])

    const toggleEditMode = () => {
        setEditMode(!editMode)
    }

    const updateStart = (value) => {
        setStart(value)
        console.log(start)
        updateEndTime(value, duration, buffer)
    }
    const updateDuration = (value) => {
        if (!value) {
            value = 0
        }
        setDuration(value)
        updateEndTime(start, value, buffer)
    }
    const updateBuffer = (value) => {
        if (!value) {
            value = 0
        }
        setBuffer(value)
        updateEndTime(start, duration, value)
    }

    const updateEndTime = (st, dur, buf) => {
        let startDate = new Date(st)
        let minutes = parseInt(dur) + parseInt(buf)
        let endDate = addMinutes(startDate, minutes)
        setEndTime(lightFormat(endDate, "HH:mm"))
    }

    return (
        <div className={`d-grid rounded column-gap-3 row-gap-3 mb-3 p-3
        ${outletContext.darkMode ? "text-light border border-light " : "text-black bg-success-subtle border border-success"}`}>
            <Row>
                <Col xs={"auto"} className={"d-flex align-items-center"}>
                    <h5>{lightFormat(appointment.start, "h:mma")} - {lightFormat(appointment.end, "h:mma")}</h5>
                </Col>
                <Col xs className={"d-flex align-items-center"}>
                    <h5>{appointment.name}</h5>
                </Col>
                <Col xs={"auto"}>
                    <Link to={`/appointment/manage/${appointment.aid}`} target={"_blank"} rel="noopener noreferrer">
                        <div
                            className={"d-flex align-items-center justify-content-center bg-primary text-light rounded-3 w-auto px-3"}
                            style={{height: "38px"}}>
                            Appointment Link <NewTab fill="white" style={{height: "25px", width: "25px"}}/>
                        </div>
                    </Link>
                </Col>
                <Col xs={"auto"}>
                    <Button type={"button"} onClick={toggleEditMode}>
                        {editMode ? "Cancel" : "Edit"}
                    </Button>
                </Col>
            </Row>
            {editMode &&
                <Row>
                    <Col className={`d-grid rounded column-gap-3 row-gap-3`}>
                        <fetcher.Form method={"post"}>
                            <Row>
                                <FormGroup xs={12} lg={3} as={Col} className={"mb-3"}
                                           controlId={"start" + appointment.aid}>
                                    <FormLabel>Start</FormLabel>
                                    <FormControl className={""} name={"newStart"} as={"input"}
                                                 type={"datetime-local"}
                                                 onChange={(e) => updateStart(e.target.value)}
                                                 defaultValue={appointment.start}/>
                                </FormGroup>
                                <FormGroup xs={6} sm={4} lg as={Col} className={"mb-3"}
                                           controlId={"duration" + appointment.aid}>
                                    <FormLabel>Duration</FormLabel>
                                    <FormControl className={""} name={"tattooDurationMinutes"}
                                                 as={"input"}
                                                 type={"number"}
                                                 min={0}
                                                 onChange={(e) => updateDuration(e.target.value)}
                                                 defaultValue={appointment.tattooDurationMinutes}/>
                                </FormGroup>
                                <FormGroup xs={6} sm={4} lg as={Col} className={"mb-3"}
                                           controlId={"buffer" + appointment.aid}>
                                    <FormLabel>Buffer</FormLabel>
                                    <FormControl className={""} name={"buffer"} as={"input"}
                                                 type={"number"}
                                                 min={0}
                                                 onChange={(e) => updateBuffer(e.target.value)}
                                                 defaultValue={appointment.bufferMinutes}/>
                                </FormGroup>
                                <FormGroup xs={12} sm={4} lg as={Col} className={"mb-3"}
                                           controlId={"endTime" + appointment.aid}>
                                    <FormLabel>End</FormLabel>
                                    <FormControl className={""} name={"endTime"} as={"input"}
                                                 type={"time"}
                                                 value={endTime}
                                                 disabled
                                                 readOnly/>
                                </FormGroup>
                                <input type={"text"} name={"aid"} value={appointment.aid} hidden readOnly/>
                                <Col xs={12} lg={2} className={"d-flex align-items-end mb-3"}>
                                    <Button className={"w-100"} type={"submit"} disabled={fetcher.state !== "idle"}
                                            name="intent" value="schedule">
                                        {fetcher.state === "idle" ? "Submit" : "Submitting"}
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-end">
                                <FormGroup xs={6} lg={2} as={Col} className={"mb-3"}
                                           controlId={"resCount" + appointment.aid}>
                                    <FormLabel>Reschedules</FormLabel>
                                    <FormControl className={""} name={"resCount"} as={"input"}
                                                 type={"number"}
                                                 min={0}
                                                 value={appointment.rescheduleCount}
                                                 disabled
                                                 readOnly/>
                                </FormGroup>
                                <Col xs={6} lg={2} className={"d-flex align-items-end mb-3"}>
                                    <Button className={"w-100"} type={"submit"} disabled={fetcher.state !== "idle"}
                                            name="intent" value="resReset">
                                        Reset Count
                                    </Button>
                                </Col>
                            </Row>
                        </fetcher.Form>
                    </Col>
                </Row>
            }
        </div>
    )
}